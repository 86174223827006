import { isDebug } from '../helpers/utils';
import amaLog from '../helpers/log';

interface GPTEvent {
  slot: {
    getSlotElementId: () => string;
  };
  advertiserId?: string;
  campaignId?: string;
  creativeId?: string;
  isEmpty?: boolean;
  lineItemId?: string;
  size?: string;
  sourceAgnosticCreativeId?: string;
  sourceAgnosticLineItemId?: string;
  inViewPercentage?: number;
}
// ToDo: Build separate script for GPT event debugging
const initGptDebugging = () => {
  if (isDebug()) {
    window.googletag.pubads().addEventListener('impressionViewable', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      amaLog(`Impression for slot ${slotId} became viewable.`);
    });

    window.googletag.pubads().addEventListener('slotOnload', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      amaLog(`Creative iframe for slot ${slotId} has loaded.`);
    });

    window.googletag.pubads().addEventListener('slotRenderEnded', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      console.group(`Slot ${slotId} finished rendering.`);
      amaLog('Advertiser ID:', event.advertiserId);
      amaLog('Campaign ID:', event.campaignId);
      amaLog('Creative ID:', event.creativeId);
      amaLog('Is empty?:', event.isEmpty);
      amaLog('Line Item ID:', event.lineItemId);
      amaLog('Size:', event.size);
      amaLog('Source Agnostic Creative ID:', event.sourceAgnosticCreativeId);
      amaLog('Source Agnostic Line Item ID:', event.sourceAgnosticLineItemId);
      console.groupEnd();
    });

    window.googletag.pubads().addEventListener('slotRequested', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      amaLog(`Slot ${slotId} has been requested.`);
    });

    window.googletag.pubads().addEventListener('slotResponseReceived', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      amaLog(`Ad response for slot ${slotId} received.`);
    });

    window.googletag.pubads().addEventListener('slotVisibilityChanged', (event: GPTEvent) => {
      const slotId = event.slot.getSlotElementId();
      console.group(`Visibility of slot ${slotId} changed.`);
      amaLog(`Visible area: ${event.inViewPercentage}%`);
      console.groupEnd();
    });
  }
};

const initGpt = () => {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  window.googletag.cmd.push(() => {
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.pubads().disableInitialLoad();
  });
  amaLog('initGPT DONE');
};

export default initGpt;
