import amaLog from '../helpers/log';
import { capitalizeFirst, isEmpty } from '../helpers/utils';

const patternRegularSubdomain = /^(?:(?!www).)*$/m;

let amaSite: string = undefined;
let amacc: string = undefined;
let amaHost: string = undefined;
let amaDomain: string = undefined;
let amaSubDomain: string = undefined;
let amaCategory: string = 'general';

export const isVideoPage = (): boolean => {
  const path = window.location.pathname;
  return path.includes('/video/');
};

const patternSubdomain = /^(\w+\.\w+\.\w+)$/m;
/**
 * Check if the current location.host is a regular (www) subdomain
 * or a blog
 * @return {boolean}
 */
export const hostIsSpecificSubdomain = (): boolean =>
  patternSubdomain.test(window.location.host) && patternRegularSubdomain.test(window.location.host);

export const isBlog = hostIsSpecificSubdomain;

const getTestSite = (): false | string => {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('dfp_id')) {
      const dfpId: string = urlParams.get('dfp_id');
      return dfpId.length > 0 ? dfpId : false;
    }
  }
  return false;
};

export const getHostName = (): string => amaHost;

export const getSiteName = (): string => amaSite;

export const isSubDomain = (): boolean => !!amaSubDomain;

const containsCategoryPath = () => location.pathname.split('/')[1] !== '';

export const resolveHost = (adScriptConfHost?: string) => {

  const winDefinedHost = window.ama?.hostName || window.hostName;

  amaLog('resolveHost optHost definedHost', adScriptConfHost, winDefinedHost);

  const host = (!isEmpty(winDefinedHost) && winDefinedHost) || (!isEmpty(adScriptConfHost) && adScriptConfHost) || window.location.hostname;
  const parts = host.split('.');
  const secondLevelDomain = parts.length > 1 ? parts[parts.length - 2] : undefined;
  const topLevelDomain = parts.length > 0 ? parts[parts.length - 1] : undefined;

  const definedSubDomain = window.ama?.subDomain || window.subDomain;
  // Use subdomain if defined by the site
  const useSub = !isEmpty(definedSubDomain);

  const domainName = secondLevelDomain ? `${secondLevelDomain}.${topLevelDomain}` : undefined;  // elle.se
  const subDomainName = useSub && domainName ? `${definedSubDomain}.${domainName}` : undefined; // elsa.elle.se

  amaHost = subDomainName || domainName || undefined; // elsa.elle.se / elle.se
  amaSite = useSub && definedSubDomain || secondLevelDomain; // elsa / elle
  amaDomain = domainName || undefined; // elle.se
  amaSubDomain = definedSubDomain || undefined; // elsa
  amaCategory = location.pathname.split('/')[1] || 'general';

  if(!amaHost || !amaSite) {
    console.error(`Ads bundle failed resolve amaHost: ${amaHost} and/or amaSite: ${amaSite}`);
  }

  amaLog('resolveHost ', parts, amaSite, amaHost, amaDomain, amaSubDomain);
}

export const getDomain = (): string => {

  if(!isEmpty(amaDomain)) return amaDomain;

  amaLog('getDomain', amaDomain);

  return amaDomain;
};

export const getSubdomainAdwatchName = (): string => {
  const components = amaHost.split('.');
  if (components.length !== 3) {
    throw Error(`Unexpected length of amaHost ${amaHost}`);
  }
  const [subdomain, domain] = components;
  const capitalizedDomain = capitalizeFirst(domain);
  return `${subdomain}${capitalizedDomain}`;
};

export const setDfpSection = (section: string | undefined = window.ama?.dfpSection): string => {
  let dfpSection = 'general'; // elle.se

  if (section) {
    dfpSection = section;
  } 
  else if (isSubDomain()) {

    dfpSection = amaSubDomain;
  }
  else if (containsCategoryPath()) {

      dfpSection = amaCategory;
  }
  amaLog('dfpSection', dfpSection);

  return dfpSection;
};

export const resolveAdUnitPathDfpId = (configDfpId: string) => {
  let resolveId = configDfpId;
  const testSite = getTestSite();

  if(testSite) {
    resolveId = testSite;
  }
  else if (resolveId === 'global' || isSubDomain()) {
    resolveId = getDomain();
  }
  return resolveId;
};

export const resolveCountryCode = (): string => {

  if (typeof amacc !== 'undefined') return amacc;

  if(amaHost) {
    const exceptions: { [key: string]: string;} = {'mabra.com' : 'mabra.se'};

    amacc = (exceptions[amaHost] ?? amaHost).split('.').pop();     
  }

  amaLog('resolveCountryCode calculated ... ', amacc);

  return amacc;
}
