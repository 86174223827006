let amaDebug = false;

export const isDebugTs = window.location.search.includes('adsdebug=1') || amaDebug;

export const isDebugTopAdTs = window.location.search.includes('adsdebug=12');

export default function amaLog(...args: any[]): void {
  if (isDebugTs) {
    if (!amaDebug) {
      amaDebug = true;
    }
    console.log('%cDFP Ads', 'color: green;', '-', ...args);
  }
}

export function amaAlert(...args: any[]): void {
  if (isDebugTs) {
    console.log('%cDFP Ads', 'color: red;', '-', ...args);
  }
}

export function amaWarn(...args: any[]): void {
  if (isDebugTs) {
    console.log('%cDFP Ads', 'color: orange;', '-', ...args);
  }
}


