import amaLog from '../helpers/log';

export const AD_LOADED_ATTRIBUTE = 'data-aller-ad-loaded';

export const AD_PLACEMENT_ATTRIBUTE = 'data-aller-ad-placement';

export const setAdLoadedForDiv = (divId: string) => {
  amaLog(`Setting ad-loaded attribute for ${divId}`);
  document.getElementById(divId).setAttribute(AD_LOADED_ATTRIBUTE,'');
};

export const setAdLoadedAttributes = (placementsToLoad: NodeListOf<HTMLElement> | HTMLElement[], adsUniqueIds: string[]) => {
  if(adsUniqueIds.length > 0 && placementsToLoad.length > 0){
    for (let i = 0; i < placementsToLoad.length; i++) {
      const div = placementsToLoad[i];

      for (let j = 0; j < adsUniqueIds.length; j++) {
        const adsUniqueId = adsUniqueIds[j];
        if(div.id.endsWith(adsUniqueId)) {
          amaLog(`setAdLoadedAttributes Setting ad-loaded attribute for ${div.id}`);
          div.setAttribute(AD_LOADED_ATTRIBUTE,'');
          break;
        }
      }
    }  
  }
};
