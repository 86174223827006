import amaLog from '../helpers/log';

interface CookieParameters {
  [key: string]: string;
}

export const setCookie = (
  name: string,
  value: string,
  validDuration: number = 300,
  priority?: 'high' | 'low' | 'medium',
): void => {
  let expires = '';
  if (validDuration) {
    let date = new Date();
    date.setTime(date.getTime() + validDuration * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  let prio = '';
  if (priority === 'high' || priority === 'low' || priority === 'medium') {
    prio = `priority=${priority}`;
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/; ' + prio;
};

export const getAllCookies = (): CookieParameters =>
  document.cookie.split(';').reduce((res, cookie) => {
    const [key, val] = cookie.trim().split('=').map(decodeURIComponent);
    try {
      return { ...res, [key]: JSON.parse(val) };
    } catch (e) {
      return { ...res, [key]: val };
    }
  }, {});

export const getCookie = (name: string): string => {
  const cookies = getAllCookies();
  amaLog('get cookie', name, cookies);
  return cookies[name];
};

export const hasRequestedTerm = (search: string[]): boolean => document.cookie.indexOf(search.join('')) > 0;

export const hasRequestedTerms = (...search: string[]): boolean =>
  search.every((searchTerm) => hasRequestedTerm([searchTerm]));

export const getCookieRows = (...args: string[]): string[] => args[0] && args[0].split && args[0].split('$') || [];

const getCookieRowsFromPairs = (rows: string[]): CookieParameters =>
  rows.length > 0
    ? rows.reduce((acc, row) => {
        const [key, value] = row.split(':');
        return key && value ? { ...acc, [key]: value } : acc;
      }, {})
    : {};

const mapCookie = (name: string): CookieParameters => {
  const cookie = getCookie(name);
  return getCookieRowsFromPairs(getCookieRows(cookie));
};

export const getCookieValue = (name: string, prop: string): string | undefined => {
  if (hasRequestedTerms(name, prop)) {
    const mapped = mapCookie(name);
    return mapped[prop];
  }
};

export default {
  getCookieValue,
  getCookie,
  setCookie,
};
