import { isDebugTs, isDebugTopAdTs } from './log';
import adSettings from '../config/adsettings';
import { pbjs } from '../prebid/index';
import { getCookie } from '../storage/cookies';
import { getModules } from '../config/modules';
import { isSubDomain } from '../config/domain';
import amaLog from './log';
import { timeFor } from './time';

export const isNativeAd = (ad: Ad): boolean => ad.placement.includes('native');

export const isNotNativeAd = (ad: Ad): boolean => !isNativeAd(ad);

export const adHasExistingContainer = (ad: Ad): boolean =>
  document.getElementById(`${ad.target}-${adSettings.adsUniqueId}`) !== null;

export const capitalizeFirst = (content: string): string =>
  (content && content[0].toUpperCase() + content.slice(1)) || '';

export const isDebug = (): boolean => isDebugTs;

export const isDebugTopAd = (): boolean => isDebugTopAdTs;

export const once = (func: Function): Function => {
  let result: any;

  return () => {
    if (!result) {
      result = func();
    }
    return result;
  };
};

export const stripDashAndAfter = (str: string): string => {
  const dashIndex = str.indexOf('-');
  return dashIndex >= 0 ? str.substring(0, dashIndex) : str;
};

export const throttle = (fn: Function, duration: number): Function => {
  let suspended = false;
  return () => {
    if (!suspended) {
      suspended = true;
      setTimeout(() => {
        fn();
        suspended = false;
      }, duration);
    }
  };
};

export const random = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isElementInViewport = (element: Element, offset: number): boolean => {
  let isWithin = false;
  if (element) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    isWithin = rect.top <= windowHeight + offset && rect.top + rect.height >= 0;
  }
  return isWithin;
};

/**
 * find out without lazyload banners
 *
 * @param {object} ad
 * @return boolean
 */
export function withOutLazyloadSlots(ad: IAdRaw): boolean {
  const { useGLazyLoad } = getModules();
  return (!useGLazyLoad || useGLazyLoad.includes(ad.placement));
}

/**
 * Helps us refresh an ad with Prebid
 *
 * @param {object} ad
 * @return void
 */
export function refreshPrebidAd(ad: IAdRaw): void {
    
  pbjs.que.push((): void => {
    // Timeout (ms) for bidrequests is set in DemandManager-dashboard
    timeFor(ad.adSlot.getSlotElementId());
    pbjs.rp.requestBids({
      gptSlotObjects: [ad.adSlot],
      callback: () => {
        amaLog(`pbjs.rp.requestBids for ${ad.adSlot.getSlotElementId()} completed after ${timeFor(ad.adSlot.getSlotElementId())}`);

        window.googletag.pubads().refresh([ad.adSlot], { changeCorrelator: false });
      },
    });
  });
}

export const isLocal = () => window.location.hostname === 'localhost' || window.location.hostname.includes('labdevs');

export const isNil = (val: any) => typeof val === 'undefined' || val === null;

export const isNotNil = (val: any) => !isNil(val);

export const isString = (val: any) => Object.prototype.toString.call(val) === '[object String]';

export const stringIfAny = (id: string | number) => (typeof id !== 'undefined' ? id.toString() : undefined);

export const googleTagIsReady = () => !!(window.googleTagInitialized && window.googleTagInitialized === true);

// Function to check if a value is empty
export const isEmpty = (value: any): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value) || typeof value === 'string') {
    return value.length === 0;
  }

  if (value instanceof Map || value instanceof Set) {
    return value.size === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false; // For other types, consider them not empty
};

export const pluck = (property: string, array: ObjectType[]): any[] => {
  return array.map((obj) => obj[property]);
};

export const isBlogSite: boolean =
  window.location.hostname.replace(/^(www\.)|^(www-stage\.)|^([^\.]+-env\.)/gm, '').split('.').length > 2;

export const setStyle = (element: HTMLElement | null, styles: { [key: string]: string }): void => {
  if (!element) return;

  const currentStyles = element.getAttribute('style') || '';
  const newStyles = Object.entries(styles)
    .map(([property, value]) => `${property}:${value};`)
    .join(' ');

  element.setAttribute('style', `${currentStyles} ${newStyles.trim()}`);
};

export const getLoginId = () => {
  const loginToken = getCookie('login_token');
  let ccValue;

  if (loginToken) {
    try {
      const payload = JSON.parse(atob(loginToken.split('.')[1]));

      ccValue = payload.data?.cc;
    } catch (error) {
      console.error('Error processing login_token:', error);
    }
  } else {
    console.debug('login_token cookie not found.');
  }
  return ccValue;
};

export const isMainAndCategoryPage = (): boolean => {
  if (isSubDomain()) return false;
  const path = window.location.pathname;
  const segments = path.split('/').filter(Boolean);

  if (path === '/') return true;

  return segments.length === 1;
};

export default {
  refreshPrebidAd,
  googleTagIsReady,
  isNil,
  isNotNil,
  isString,
  withOutLazyloadSlots,
  isEmpty,
  pluck,
  getLoginId,
  isMainAndCategoryPage,
};
