import { GPT_EVENTS } from './events';
import { AdStats } from './adstats';
import { isDebug, random } from '../helpers/utils';
import { isBlog, getSiteName, getSubdomainAdwatchName } from '../config/domain';
import amaLog from '../helpers/log';
import { COLLECTOR } from './constants';
import statssettings from './statssettings';

interface Handler {
  event: string;
  decorate?: Function;
}

interface Targeting {
  [key: string]: string[];
}

interface Slot {
  getTargeting: (key: string) => string[];
}

const gptEventHandlers: Handler[] = [
  {
    event: GPT_EVENTS.slotRequested,
  },
];

const getHandlersForEvent = (gptEvent: string): Handler[] =>
  gptEventHandlers.filter((handler) => handler.event === gptEvent);

const isValidTargeting = (targeting: string[]): boolean => Array.isArray(targeting) && targeting.length > 0;

const getPositionFromSlot = (slot: Slot): string | undefined => {
  const targeting = slot.getTargeting('pos');
  return isValidTargeting(targeting) ? targeting[0] : undefined;
};

const slotStat = (position: string, gptEvent: string) => ({ placement: position, gptEvent });

const handlerHasDecorator = (handler: Handler): boolean => handler.decorate && typeof handler.decorate === 'function';

const onGptEvent = (targetEvent: string) => (gptEvent: { slot: Slot }) => {
  try {
    const pos = getPositionFromSlot(gptEvent.slot);
    const stat = slotStat(pos, targetEvent);
    const handlers = getHandlersForEvent(targetEvent);
    const stats = handlers.map((handler) => (handlerHasDecorator(handler) ? handler.decorate!(stat) : stat));

    if(window.ama) {
      window.ama.adStats.add(stats);
    } else {
      window.adStats.add(stats);
    }
  } catch (e) {
    amaLog(`onGptEvent:: Unable to process stats for ${targetEvent}`);
  }
};

const addEventListeners = (): void => {
  window.googletag.pubads().addEventListener(GPT_EVENTS.slotRequested, onGptEvent(GPT_EVENTS.slotRequested));
};

const removeEventListeners = (): void => {
  window.googletag.pubads().removeEventListener(GPT_EVENTS.slotRequested, onGptEvent(GPT_EVENTS.slotRequested));
};

const isWithinSamplerate = (): boolean => random(0, 100) <= (statssettings.get() as any)['samplerate'];
const isAllowedToCollect = (): boolean =>
  isDebug() || ((statssettings.get() as any)['enabled'] && isWithinSamplerate());

let pushInterval: number;
export const collector = {
  start: (options: any = {}): void => {
    statssettings.get(options);

    const whitelistedBlogs = (statssettings.get() as any)['whitelistedBlogs'];
    const blog = isBlog();
    if (blog && !whitelistedBlogs.includes(window.location.host)) {
      amaLog(`adWatch:: ${location.host} is a not whitelisted subdomain`);
    } else if (isAllowedToCollect()) {
      const site = blog ? getSubdomainAdwatchName() : getSiteName();
      
      if(window.ama) {
        window.ama.adStats = new (AdStats as any)(site);
      } else {
        window.adStats = new (AdStats as any)(site);
      }
      window.googletag.cmd.push(addEventListeners);

      pushInterval = setInterval(window.ama?.adStats.push || window.adStats.push, COLLECTOR.pushInterval) as unknown as number;
    }
  },
  stop: (): void => {
    removeEventListeners();
    clearInterval(pushInterval);
  },
};
