import { isDebugTs } from './log';

const allerTime: {[key: string]: DOMHighResTimeStamp} = {};

export const timeFor = (key: string, action?: string) => {
  if(!isDebugTs) return;
  const now = Math.floor(performance.now());
  if(!key) return now;
  if(allerTime[key]) {
    if(action && action !== 'stop') {
      console.warn(`Start Time already set. Use 'stop' to end`);
    }
    return `${now} (diff: ${now - allerTime[key]}) ms`;
  }
  if(!allerTime[key]) {
    allerTime[key] = now;
    return `${allerTime[key]} ms`;
  }
};