import waitFor from '../helpers/waitfor';
import amaLog from '../helpers/log';
import loadScript from './loadScript';
import { AdsConfigJson } from '../config/platform';
import { loadConfig, LoadedByAdsLib } from '../config/platform';
import { getModules } from '../config/modules';
import { isVideoPage, resolveCountryCode } from '../config/domain';
import { isBlog } from '../config/domain';
import { getSiteName } from '../config/domain';
import { useTopAds } from '../config/platform';

let isTcfapiLoaded = false;
let isDemandManagerLoaded = false;

export const resourceTimeout = 20000;

export const isAdResourcesLoaded = () => isTcfapiLoaded && isDemandManagerLoaded;

const isAnyPlacementsLoaded = () => {

  const domLoaded = window.ama ? window.ama?.domLoaded : window?.loadedAdPlacement;
  return domLoaded && Object.entries(domLoaded).length > 0;
}

const loadTopAdPlacement = async (topAdTarget: string) => {
  const countryCode = resolveCountryCode();
  
  if ( countryCode === 'se' && !isBlog() && !getSiteName().includes('recept')) {

    amaLog('loadTopAdPlacement ...', topAdTarget);

    // ToDo: Move top-ad placement handling to platform in se-ocelot  
    const isApp = window.location.search.includes('allerapp=1');
    if (!isApp) {

      let topAdElement = document.createElement('div');
      topAdElement.id = `${topAdTarget}`;
      topAdElement.setAttribute('data-aller-ad-placement', 'top_ad');

      await waitFor(() => typeof window?.document?.body?.insertBefore !== 'undefined', { timeout: 5000, errorMessage: `Timeout: window.document.body missing. top_ad is not injected` }).then(() => {

        window.document.body.insertBefore(topAdElement, window.document.body.firstChild);
      });              
    }
  }
}

const loadDemandManager = async (loadedByAdsLib: LoadedByAdsLib) => {
  if (loadedByAdsLib?.demandManager) {

    const { prebidScript, usePlacementCheck } = getModules();

    amaLog('usePlacementCheck', usePlacementCheck);

    if ( usePlacementCheck ) {
      
      if (!isAnyPlacementsLoaded() && !isVideoPage()) {
        amaLog(`Ads:: Wait for Placement Wrappers to be loaded ...`);
        await waitFor(isAnyPlacementsLoaded, {
          timeout: 20000,
          errorMessage: `Timeout: Placementwrappers are missing `,
        });
      }
      amaLog('Ads:: Placement Wrappers Loaded for ');
    }

    try {
      if(window.ama ? window.ama.adsUseDemandManager === 'true' : window.adsUseDemandManager === 'true') {
        loadScript(prebidScript, { 
          async: true,
          defer: false,
          strategy: "beforeInteractive",
          referrerpolicy: "strict-origin-when-cross-origin"
        }).then( () => {
          isDemandManagerLoaded = true;
          amaLog('DemandManagerLoaded');
        });  
      } else {
        amaLog('DemandManagerLoaded DONE - not used');
        isDemandManagerLoaded = true;
      }
    } catch (error) {
      console.error('DemandManager failed to Load', error);
      isDemandManagerLoaded = true;
    }  
  } else {
    amaLog('DemandManager not loaded by ads-lib');
    isDemandManagerLoaded = true;
  }
};

export const initAdResources = async (parsedConfig: AdsConfigJson) => {

  amaLog('initAdResources ...');

  // Load platformConfig if available
  const config = loadConfig(parsedConfig);

  if(useTopAds()) {
    const { topAdTarget = `top_ad-${window.ama?.adsUniqueId || window.adsUniqueId}`} = parsedConfig;

    loadTopAdPlacement(topAdTarget);  
  }

  loadDemandManager(parsedConfig.loadedByAdsLib);

  // Load tcfapi
  waitFor(() => typeof window?.__tcfapi !== 'undefined', { timeout: resourceTimeout, errorMessage: `Timeout: window.__tcfapi not available ` }).then(() => {
    isTcfapiLoaded = true;
    amaLog('tcfApi is loaded');
  });

  amaLog('waitFor isAdResourcesLoaded ...');

  await waitFor(isAdResourcesLoaded, { timeout: resourceTimeout, errorMessage: `Timeout: isAdResourcesLoaded failed ` });

  return config;
};
