import { resolveCountryCode, isSubDomain } from './domain';
import { usePlacementCheck, useTopAds } from './platform';
import { getSiteName } from './domain';

type CountryCode = 'se' | 'fi' | 'dk' | 'no';

interface CountryOptions extends Partial<Record<CountryCode, Modules>> {}

const PREBID_SCRIPT = {
  se: 'https://micro.rubiconproject.com/prebid/dynamic/13012.js',
  fi: 'https://micro.rubiconproject.com/prebid/dynamic/13046.js',
  dk: 'https://micro.rubiconproject.com/prebid/dynamic/15518.js',
  no: '',
};

let countryModules: CountryOptions = {};

export const getModules = (): Modules => {
  const countryCode = resolveCountryCode() as CountryCode;

  if (countryModules[countryCode]) return countryModules[countryCode];

  let modules: Modules = {};
  const siteName = getSiteName();
  modules.prebidScript = PREBID_SCRIPT[countryCode];
  modules.usePlacementCheck = usePlacementCheck();

  switch (countryCode) {
    case 'se':
      modules = {
        ...modules,
        useStats: true,
        useLimitedAds: true,
        useGrowthbook: true,
        useSeenthis: useTopAds(),
        useGLazyLoad: ['responsive_1', 'top_ad'],
        useDivModificationsOnMainCat: true,
        usePlacementObserver: ['motherhood','sandrabeijer'].includes(siteName),
        useSecondArticle: !isSubDomain() && !siteName.includes('recept'),
      };
      break;
    case 'no':
      break;
    case 'dk':
      break;
    case 'fi':
      modules = {
        ...modules,
        usePlacementObserver: true,
        useStats: true,
        useGLazyLoad: ['responsive_1'],
      };
      break;
  }

  countryModules[countryCode] = modules;

  return modules;
};
