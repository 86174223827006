import AdsManager from './AdsManager';
import { configureForDevelopment } from './placement/local';
import { enableGrowthbook, getGrowthbookGroupVideo } from './abtest/growthbook';
import adSettings from './config/adsettings';
import { getAdScheduleId } from './config/adschedule';
import ppid from './config/ppid';
import adConfig, { modifyMainCategoryPlacementsDivs } from './config/adslots';
import initConsent, { checkForUserConsent } from './consent/consent';
import initGpt from './googletag/googletag';
import amaLog, { amaAlert, amaWarn, isDebugTs } from './helpers/log';
import { timeFor } from './helpers/time';
import { collector } from './adstats/collector';
import waitFor from './helpers/waitfor';
import { initAdResources } from './resource/resource';
import { isVideoPage } from './config/domain';
import { isMainAndCategoryPage, stringIfAny } from './helpers/utils';
import { getModules } from './config/modules';
import { hasTcfConsent } from './consent/tcfv2';
import { getHostName } from './config/domain';
import { parseAdsConfig } from './config/platform';
import { setAdLoadedForDiv, AD_PLACEMENT_ATTRIBUTE, AD_LOADED_ATTRIBUTE } from './placement/placement';

amaLog(`Start running adsBundle ${timeFor('runBundle')}`);

let processedUniqueIds: string[] = [];
let proccessedPlacementIds = new Set<string>();
let serverLoadedConfig: IAdsConfig | undefined;
let amaPageNo = [...processedUniqueIds].length;

const hasUniqueIdAndNotProcessed = (uniqueId: string | number) => isUniqueIdDefined(uniqueId) && !isProcessed(uniqueId);
const isUniqueIdDefined = (id: string | number) => typeof id !== 'undefined';
const isProcessed = (uniqueId: string | number) => processedUniqueIds.length > 0 && processedUniqueIds.includes(`${uniqueId}`);
const isValidUniqueId = (uniqueId: string | number) =>{
  return hasUniqueIdAndNotProcessed(uniqueId);
}

const placementLog = (uniqueId: string) => {

  if (isDebugTs) {

    const { usePlacementObserver } = getModules();

    if (usePlacementObserver) {
    
      amaLog('observer - placementLog: ', { uniqueId, processedUniqueIds, proccessedPlacementIds });
    
      if (uniqueId !== '') {
        const isUniqueIdHandled = processedUniqueIds.includes(uniqueId);
        !isUniqueIdHandled && amaAlert(`placementLog - ${uniqueId} is NOT handled`);
    
        const foundPlacements = document.querySelectorAll(`div[id$='${uniqueId}'][${AD_PLACEMENT_ATTRIBUTE}]`);
    
        Object.entries(foundPlacements).map((placement) => {
        
          const placementId = placement[1].id;
          const isPlacementHandled = proccessedPlacementIds.has(placementId);
      
          !isPlacementHandled && amaAlert(`placementLog - ${placementId} is NOT handled`);
        });
      }  
    }
  }
}

const isPlacementsLoaded = (uniqueId: string) =>
  window.ama ? window.ama?.domLoaded?.[uniqueId] : window?.loadedAdPlacement?.[uniqueId];

/**
 * Initializes the loading of ads on a page.
 * @param uniqueId The unique ID appended to placements div-ids.
 * @param options Custom settings, e.g., { dfpSection = "..." }.
 * @returns A Promise that resolves once the initialization is complete.
 */
const initializeAds = async (
  adsUniqueId: string | number = window.ama?.adsUniqueId || window.adsUniqueId,
  options: InitOptions = {},
): Promise<void> => {
  amaLog('initializeAds()', adsUniqueId, options, timeFor('initializeAds'));

  const { 
    useStats,
    useLimitedAds,
    useGrowthbook,
    usePlacementCheck,
    useDivModificationsOnMainCat,
    usePlacementObserver,
    useSecondArticle
  } = getModules();
  
  try {
    amaLog('adsUniqueId, stringIfAny(adsUniqueId)', adsUniqueId, stringIfAny(adsUniqueId), 'usePlacementObserver',usePlacementObserver);
    let uniqueId = stringIfAny(adsUniqueId);
    let { dfpSection, isSecondArticle } = options;
    const videoOnly = isVideoPage();

    if(usePlacementObserver) {
      if(window.ama){
        window.ama.adsUniqueId = adsUniqueId;
      } else {
        window.adsUniqueId = adsUniqueId;
      }
    } else {
      if (!videoOnly && !isValidUniqueId(uniqueId)) {
        processedUniqueIds.includes(uniqueId) && console.warn(`Ads:: already processed ${uniqueId}`);
        amaLog(
          `Ads:: Valid uniqueId not set yet, waiting for updated ${
            window.ama ? 'window.ama.adsUniqueId' : 'window.adsUniqueId'
          } try no ...`,
        );
        await waitFor(
          () => isValidUniqueId(stringIfAny(window.ama?.adsUniqueId || window.adsUniqueId)),
          {
            timeout: 10000,
            errorMessage: `Timeout: Valid adsUniqueId is missing - ${uniqueId} `,
          },
        );
  
        // We cancel init if adsUniqueId is processed anyway.
        if (isProcessed(window.ama?.adsUniqueId || window.adsUniqueId)) {
          amaLog('initializeAds() was cancelled due to already processed ', adsUniqueId, options);
          return;
        }
        uniqueId = stringIfAny(window.ama?.adsUniqueId || window.adsUniqueId);
      }
  
      !uniqueId && amaAlert('Ads:: uniqueId is missing. it might be set to late');
  
      uniqueId && amaLog(`Ads:: Valid uniqueId '${uniqueId}' set after ${timeFor('id')}`);
  
      processedUniqueIds.push(uniqueId);  
    }

    adSettings.refresh();

    amaLog('Get config for ', uniqueId, getHostName(), adSettings.device, { ...adSettings });

    amaPageNo = [...processedUniqueIds].length;

    timeFor('confLoad');
    useDivModificationsOnMainCat && isMainAndCategoryPage() && modifyMainCategoryPlacementsDivs();
    let config: any = await adConfig.getModifiedConfig(amaPageNo, serverLoadedConfig);

///////////////////
//////////////////////////////////////////
/////////////

    config = adConfig.addExtraPlacementsToConfig(config, 'intext');
    config = adConfig.addExtraPlacementsToConfig(config, 'responsive');

    if (usePlacementCheck && !videoOnly) {
      amaLog('usePlacementCheck', usePlacementCheck);

      if (!isPlacementsLoaded(uniqueId)) {
        amaLog(`Ads:: Wait for Placement Wrappers to be loaded for ${uniqueId} ...`);
        await waitFor(() => isPlacementsLoaded(uniqueId), {
          timeout: 20000,
          errorMessage: `Timeout: Placementwrappers are missing for ${uniqueId}`,
        });
      }
      amaLog('Ads:: Placement Wrappers Loaded for ', uniqueId);
    }

    let filteredAds: any = adSettings.filterAds ? adConfig.filter(config, adSettings.isNativeAd) : config;

    amaLog(`filteredConfig ${timeFor('confLoad', 'stop')}`, filteredAds);

    const tcData = await initConsent();

    amaLog(`Got Consent ${timeFor('consent')}`);

    initGpt();

    useStats && collector.start(config?.statscollector);

    useGrowthbook && (await enableGrowthbook());
    const hasConsent = (await checkForUserConsent()) || hasTcfConsent(tcData);
    if (useLimitedAds && !hasConsent) {
      amaLog(`useLimitedAds`);

      if (window.ama) {
        window.ama.adsUseDemandManager = 'false';
      } else {
        window.adsUseDemandManager = 'false';
      }
    }
    if(usePlacementObserver && useSecondArticle && amaPageNo > 1) {
      amaLog(`observer waitFor isSecondArticle `);           
      await waitFor(() => typeof window.ama?.isSecondArticle !== 'undefined',
        {
          timeout: 2000,
          errorMessage: `Timeout: window.ama.isSecondArticle is missing `,
        }
      );
      isSecondArticle = !!window.ama?.isSecondArticle;
      amaLog(`observer wait done for isSecondArticle. Calling init(): adsUniqueId ${adsUniqueId} isSecondArticle: ${isSecondArticle} `);           
    }

    AdsManager.init(filteredAds, ppid.get(), uniqueId, dfpSection, amaPageNo, hasConsent, videoOnly, isSecondArticle, placementLog);
  } catch (error) {
    console.error('Error initializing ads:', error);
  }
};

const registerProccesedUniqueId = (adsUniqueId: string, divId?: string) => {
  processedUniqueIds.push( adsUniqueId );

  amaLog(`observer register adsUniqueId "${adsUniqueId}" for ${divId} `);
};

const registerPlacementId = (divId?: string) => {
  if (proccessedPlacementIds.has(divId)) {
    amaWarn(`observer - ${divId} already registred `);
    return;
  }
  proccessedPlacementIds.add(divId);
  amaLog(`observePlacements registerPlacementId ${divId}`);
  return true;
};

const validPlacementUniqueId = (div: HTMLElement) => {

  amaLog("observePlacements New ad div detected:", div);

  const match = div.id.match(/(responsive|intext_ad|top_ad)_(\d+)-(.+)?/);
  if (!match) return;

  const [divId, type, no, uniqueId] = match;

  amaLog(`observePlacements uniqueId:${uniqueId} defined:${isUniqueIdDefined(uniqueId)} notProcessedUniqueId:${!isProcessed(uniqueId)} `);

  return isValidUniqueId(uniqueId) && uniqueId;
};

const notLoadedAdPlacementSelector = `div[${AD_PLACEMENT_ATTRIBUTE}]:not([${AD_LOADED_ATTRIBUTE}])`;

const handleFoundPlacement = (div: HTMLElement) => {

  // ToDo: set div-id for placements if they are missing

  amaLog("observer - handleFoundPlacement ", div.id);

  setAdLoadedForDiv(div.id);

  // register handled div id, to make sure we only proccess each div once
  if (registerPlacementId(div.id)) {

    // validate if placement has valid adsUniqueId (is not empty and not processed)
    const adsUniqueId = validPlacementUniqueId(div);

    if( adsUniqueId) {

      // only call initializeAds with same adsUniqueId once  
      registerProccesedUniqueId(adsUniqueId, div.id);
      amaLog(`observer calls initializeAds with adsUniqueId ${adsUniqueId} isSecondArticle: ${!!window.ama?.isSecondArticle} `);
      initializeAds(adsUniqueId, { dfpSection: window.ama?.dfpSection });
    } else {
      amaWarn(`observePlacements adsUniqueId ${adsUniqueId} not valid/ already handled for ${div.id}`);
    }
  }
};

const placementObserver = new MutationObserver((mutationsList: MutationRecord[]) => {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      for (const node of mutation.addedNodes as NodeListOf<HTMLElement>) {
        if (node.nodeType === 1) { 
          if (node.matches(notLoadedAdPlacementSelector)) {

            handleFoundPlacement(node);
          }
          const nestedAdPlacements = node.querySelectorAll(notLoadedAdPlacementSelector);

          for (const child of nestedAdPlacements as NodeListOf<HTMLElement>) {

            handleFoundPlacement(child);
          }
        }
      }
    }
  }
});

const hasPlacementsToHandle = () => {

  amaLog(`hasPlacementsToHandle ... `);

  // Scan for placements that are not marked handled (ad-loaded)
  const placementsToLoad = 
    document.querySelectorAll<HTMLElement>(notLoadedAdPlacementSelector);

  if(placementsToLoad.length > 0) {

    amaLog(`PlacementManager - Found ${placementsToLoad.length} placements to handle`, placementsToLoad);

    for (let i = 0; i < placementsToLoad.length; i++) {

      handleFoundPlacement(placementsToLoad[i]);      
    }
  }
};

// get attribute ads-conf from current script
const adsPlatformConfJson = document.getElementById('ads-manager')?.getAttribute('data-ads-conf') || undefined;

const parsedConfig = parseAdsConfig(adsPlatformConfJson);

amaLog('adsPlatformConfJson', parsedConfig);

initAdResources(parsedConfig)
  .then((config) => {
    amaLog('AdResources loaded');

    if (config) {
      serverLoadedConfig = config;
    }
    const { usePlacementObserver } = getModules();
    if (window.ama) {
      window.ama.init = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {

        amaLog('window.ama.init called initializeAds ...', options);


        if (options?.dfpSection === 'general') {
          // Remove posibility to override to a general section
          // ToDo: Remove when response from ads-microservice no longer has dfpSection
          options = { ...options, dfpSection: undefined };
        }
        amaLog('options', options);

        if(usePlacementObserver) {

          if( amaPageNo > 1 ) {

            const { isSecondArticle } = options;
            amaLog('opserver - isSecondArticle', isSecondArticle);

            window.ama.isSecondArticle = !!isSecondArticle;
          }
        
          amaLog('DFPInit - window.ama.init Cancelled. Observer will handle placements');
          return;
        }  
        initializeAds(uniqueId, options);
      };
      amaLog('window.ama.init available');
    }
    window.DFPInit = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {

      amaLog('window.DFPInit called initializeAds ...', options);

      if(usePlacementObserver) {
        amaLog('window.DFPInit Cancelled. Observer will handle placements');
        return;
      }

      if (options?.dfpSection === 'general') {
        // Remove posibility to override to a general section
        // ToDo: Remove when response from ads-microservice no longer has dfpSection
        options = { ...options, dfpSection: undefined };
      }
      amaLog('options', options);

      initializeAds(uniqueId, options);
    };
    amaLog('window.DFPInit available');

    adSettings.init();
    
    if(usePlacementObserver) {

      amaLog('Init Observer for handling placements');

      placementObserver.observe(document.body as HTMLElement, { childList: true, subtree: true });
      hasPlacementsToHandle();

    } else {
      adSettings.autoloadAds && initializeAds(window.ama?.adsUniqueId || window.adsUniqueId);    
    }

    window.ads = {
      getAdScheduleId,
      getVideoExperimentGroup: getGrowthbookGroupVideo,
      getVideoPpId: ppid.get,
    };
    if (window.ama) {
      window.amav = {
        getAdScheduleId,
        getVideoExperimentGroup: getGrowthbookGroupVideo,
        getVideoPpId: ppid.get,
      };
      amaLog('window.ama.video available');
    }

    amaLog('window.ads available');
  })
  .catch((error) => {
    console.error('AdResources fail to load', error);
  });

