import amaLog from '../helpers/log';

interface StatsSettingsOptions {
  samplerate?: number;
  enabled?: boolean;
  whitelistedBlogs?: string[];
}

interface StatsSettings {
  samplerate: number;
  enabled: boolean;
  whitelistedBlogs: string[];
}

class StatsSettingsProvider {
  private defaults: StatsSettings = {
    samplerate: 5,
    enabled: true,
    whitelistedBlogs: ['elsa.elle.se', 'sandrabeijer.elle.se', 'fredriksfika.allas.se', 'isabellalowengrip.allas.se'],
  };

  private settings = {};

  private makeSettings(opts: StatsSettingsOptions) {
    this.settings = {
      ...this.defaults,
      ...opts,
    };

    amaLog('statsSettings:: ', this.settings);

    return this.settings;
  }

  get(options: StatsSettingsOptions = {}) {
    return Object.keys(this.settings).length === 0 ? this.makeSettings(options) : this.settings;
  }
}

export default new StatsSettingsProvider();
